<template>
  <v-app>
    <v-main>
      <img-default
        sizes="1920px"
        cover
        v-bind="imgProps"
      >
        <v-layout full-height>
          <v-app-bar
            flat
            color="transparent"
          >
            <v-container
              fluid
              class="py-0 flex"
            >
              <nuxt-link
                to="/"
                class="flex-grow-0"
              >
                <u-logo />
              </nuxt-link>
            </v-container>
          </v-app-bar>
          <v-main tag="div">
            <v-container height="100%">
              <v-row
                class="md:h-full"
                no-gutters
                align-md="center"
              >
                <v-col
                  cols="auto"
                  class="relative before:content-[''] before:bg-white before:block before:absolute before:left-[-50px] before:top-0 before:bottom-0 before:right-0 before:blur-3xl before:opacity-60 before:z-[-1]"
                >
                  <v-row
                    no-gutters
                    class="mb-6"
                  >
                    <v-col
                      cols="24"
                      md="15"
                      lg="13"
                    >
                      <slot name="title" />
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mb-8"
                  >
                    <v-col
                      cols="24"
                      md="13"
                      lg="10"
                      xl="10"
                      xxl="10"
                    >
                      <slot name="content" />
                    </v-col>
                  </v-row>

                  <slot />

                  <trustpilot-widget
                    class="mt-8"
                    schema-type="Organization"
                    stars="5"
                    style-height="90px"
                    style-width="150px"
                    template-id="53aa8807dec7e10d38f59f32"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-main>
          <v-footer
            app
            color="transparent"
            class="block text-white before:content-[''] before:bg-black before:block before:absolute before:left-[-50px] before:top-0 before:bottom-[-50px] before:right-0 before:blur-3xl before:opacity-60 before:z-[-1]"
          >
            <v-container class="py-5">
              <v-row
                justify="center"
                justify-md="start"
                align="center"
              >
                <v-col
                  cols="auto"
                  lg="4"
                  class="max-lg:hidden"
                >
                  As featured by
                </v-col>
                <template
                  v-for="(partner, index) in partners"
                  :key="index"
                >
                  <v-col
                    cols="8"
                    md
                    lg="4"
                  >
                    <v-img
                      v-bind="partner"
                      class="mx-auto"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <v-container
              fluid
              class="py-5 text-[9px] leading-[1.67] border-solid border-t border-x-0 border-b-0 border-t-white/30"
            >
              <u-text>
                &copy; 2021 - {{ new Date().getFullYear() }} unbiased.co.uk
              </u-text>
              <u-text>
                Find an IFA, Independent Financial Advisers, Whole of Market Financial advisers, Mortgage advisers, Solicitors and Accountants, Adviser Search, financial guides, financial tools and impartial information on professional financial and legal advice. 0800 020 9430. Legals.
              </u-text>
            </v-container>
          </v-footer>
        </v-layout>
      </img-default>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import bbc from '~/assets/images/bbc-white.png'
import independent from '~/assets/images/independent-white.png'
import telegraph from '~/assets/images/telegraph-white.png'
import times from '~/assets/images/times-white.png'
import guardian from '~/assets/images/guardian-white.png'

const {
  background,
  mobileBackground,
} = defineProps<{
  background: Record<any, any>
  mobileBackground: Record<any, any>
}>()

defineSlots<{
  title: any
  content: any
  default: any
}>()

const { height, width, mdAndDown, smAndDown } = useDisplay()
const imgProps = computed(() => {
  return {
    ...(
      mdAndDown.value && (width.value > height.value)
        ? {
            minHeight: '100vh',
          }
        : {
            height: '100vh',
          }
    ),
    ...(
      smAndDown.value
        ? {
            src: mobileBackground.filename,
            sizes: '640px sm:768px',
          }
        : {
            src: background.filename,
          }
    ),
  }
})
const partners = [
  {
    src: times,
    alt: 'The Times',
    maxWidth: 175,
  },
  {
    src: telegraph,
    alt: 'Tha Daily Telegraph',
    maxWidth: 177,
  },
  {
    src: bbc,
    alt: 'BBC',
    maxWidth: 72,
  },
  {
    src: guardian,
    alt: 'The Guardian',
    maxWidth: 154,
  },
  {
    src: independent,
    alt: 'Independent',
    maxWidth: 176,
  },
]
</script>
